const SidebarStatusKey = 'sidebarStatus'
const TokenKey = 'token'
const UserKey = 'user'

export default {
  getSidebarStatus () {
    const status = window.localStorage.getItem(SidebarStatusKey)
    if (!status) {
      return 'open'
    }
    return status
  },
  setSidebarStatus (status) {
    window.localStorage.setItem(SidebarStatusKey, status)
  },
  getToken () {
    return window.sessionStorage.getItem(TokenKey)
  },
  setToken (token) {
    window.sessionStorage.setItem(TokenKey, token)
  },
  removeToken () {
    window.sessionStorage.removeItem(TokenKey)
  },
  getUser () {
    const user = window.sessionStorage.getItem(UserKey)
    if (!user) {
      return null
    }
    return JSON.parse(user)
  },
  setUser (user) {
    window.sessionStorage.setItem(UserKey, JSON.stringify(user))
  },
  removeUser () {
    window.sessionStorage.removeItem(UserKey)
  }
}

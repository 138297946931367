import store from '@/store'

const OAuthUrl = 'https://auth.begind.cn/oauth/authorize'
const OAuthExitUrl = 'https://auth.begind.cn/oauth/exit'
const ForbidNonce = 'JM3TSCgOrnLGB7V69qfZ'

const checkPrivileges = (values, privileges) => {
  if (!privileges || privileges.length <= 0) {
    return true
  }
  // 如果用户信息中没有附带权限或者权限为空，不可访问
  if (!values || values.length <= 0) {
    return false
  }
  // 如果没有权限资格或权限资格为空，所有用户都可访问
  // 用户权限与权限配置对比，存在可访问
  for (const v of values) {
    if (privileges.includes(v)) {
      return true
    }
  }
  return false
}

const checkPrivilegesByPath = (values, path) => {
  const routerPrivilege = store.getters.routerPrivilege
  const privileges = routerPrivilege.get(path)
  return checkPrivileges(values, privileges)
}

export default {
  OAuthUrl,
  OAuthExitUrl,
  ForbidNonce,
  checkPrivileges,
  checkPrivilegesByPath
}

import store from '@/store/store'

const app = {
  state: {
    device: 'desktop',
    sidebar: {
      opened: store.getSidebarStatus() === 'open',
      withoutAnimation: false
    },
    routerPrivilege: new Map()
  },
  getters: {
    device: state => state.device,
    sidebar: state => state.sidebar,
    isMobile: state => { return state.device === 'mobile' },
    routerPrivilege: state => state.routerPrivilege
  },
  mutations: {
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    TOGGLE_SIDEBAR: state => {
      state.sidebar.opened = !state.sidebar.opened
      state.sidebar.withoutAnimation = false
      if (state.sidebar.opened) {
        store.setSidebarStatus('open')
      } else {
        store.setSidebarStatus('close')
      }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      store.setSidebarStatus('close')
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    }
  },
  actions: {
    ToggleDevice: ({ commit }, device) => {
      commit('TOGGLE_DEVICE', device)
    },
    ToggleSideBar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR')
    },
    CloseSideBar: ({ commit }, { withoutAnimation }) => {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    }
  }
}

export default app
